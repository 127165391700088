import React from 'react';
import { number, objectOf, oneOf, shape, string } from 'prop-types';
import classNames from 'classnames';

import Hero1 from '../../../../assets/Hero1.png';
import Hero2 from '../../../../assets/Hero2.png';

import css from './CustomAppearance.module.css';

// BackgroundImage doesn't have enforceable aspect ratio
export const CustomAppearance = React.forwardRef((props, ref) => {
  const {
    className,
    rootClassName,
    backgroundColor,
    backgroundImage,
    backgroundImageOverlay,
    alt,
    pageType,
    sizes,
  } = props;

  const getVariantNames = img => {
    const { variants } = img?.attributes || {};
    return variants ? Object.keys(variants) : [];
  };

  const backgroundColorMaybe = backgroundColor ? { backgroundColor } : {};
  const { preset, color: overlayColor, opacity: overlayOpacity } = backgroundImageOverlay || {};
  const hasBackgroundOverlay = typeof preset === 'string' && preset !== 'none';
  const overlayStyle = hasBackgroundOverlay
    ? { backgroundColor: overlayColor, opacity: overlayOpacity }
    : {};

  const classes = classNames(rootClassName || css.backgroundImageWrapper, className);
  const isLandingPage = pageType === 'landing';
  const imgSrc =
    backgroundImage?.attributes?.variants?.default?.url ||
    backgroundImage?.attributes?.variants?.scaled2400?.url;

  return (
    <div className={classes} style={backgroundColorMaybe}>
      {isLandingPage && <img src={Hero1} alt="Hero 1" className={css.heroImageLeft} />}

      {backgroundImage ? (
        <div className={css.backgroundImageWrapper}>
          <img className={css.backgroundImage} ref={ref} alt={alt} src={imgSrc} />
        </div>
      ) : null}

      {hasBackgroundOverlay ? <div className={css.backgroundOverlay} style={overlayStyle} /> : null}

      {isLandingPage && <img src={Hero2} alt="Hero 2" className={css.heroImageRight} />}
    </div>
  );
});

CustomAppearance.displayName = 'CustomAppearance';

CustomAppearance.defaultProps = {
  rootClassName: null,
  className: null,
  alt: 'background image',
  sizes: null,
  backgroundColor: null,
  backgroundImage: null,
  backgroundImageOverlay: null,
};

CustomAppearance.propTypes = {
  rootClassName: string,
  className: string,
  backgroundColor: string,
  backgroundImage: shape({
    id: string.isRequired,
    type: oneOf(['imageAsset']).isRequired,
    attributes: shape({
      variants: objectOf(
        shape({
          width: number.isRequired,
          height: number.isRequired,
          url: string.isRequired,
        })
      ).isRequired,
    }).isRequired,
  }),
  backgroundImageOverlay: shape({
    preset: string.isRequired,
    color: string.isRequired,
    opacity: number.isRequired,
  }),
  alt: string,
  sizes: string,
};
