import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import { FieldSelect } from '../../../../../components';
import classNames from 'classnames';

import { intlShape, injectIntl } from '../../../../../util/reactIntl';
import { Form, LocationAutocompleteInput } from '../../../../../components';

import IconSearchDesktop from './IconSearchDesktop';

import css from './SearchForm.module.css';

const identity = v => v;

const KeywordSearchField = props => {
  const { intl, inputRef } = props;
  const KEYWORD_OPTIONS = [
    { label: 'Braids', key: 'braids' },
    { label: 'Twists', key: 'twists' },
    { label: 'Locs', key: 'locs' },
    { label: 'Natural Hair', key: 'natural hair' },
    { label: 'Bridal', key: 'bridal' },
  ];
  return (
    <div className={css.keywordSearchWrapper}>
      <FieldSelect
        selectClassName={css.keywordSelectInput}
        className={css.keywordInput}
        name="keywords"
        id={'keyword-search-hero'}
      >
        <option disabled value="">
          What are you looking for?
        </option>
        {KEYWORD_OPTIONS.map(optionConfig => {
          const key = optionConfig.key;
          return (
            <option key={key} value={key}>
              {optionConfig.label}
            </option>
          );
        })}
      </FieldSelect>
    </div>
  );
};

const LocationSearchField = props => {
  const { intl, handleChange } = props;
  return (
    <Field
      name="location"
      format={identity}
      render={({ input, meta }) => {
        const { onChange, ...restInput } = input;

        const searchOnChange = value => {
          onChange(value);
          handleChange(value);
        };

        const searchInput = { ...restInput, onChange: searchOnChange };
        return (
          <LocationAutocompleteInput
            placeholder="Zip/Postal code"
            rootClassName={css.locationRoot}
            iconClassName={css.noSearchIcon}
            inputClassName={css.searchInput}
            predictionsClassName={css.searchPredictions}
            input={searchInput}
            meta={meta}
          />
        );
      }}
    />
  );
};

const SearchFormComponent = props => {
  const [serviceLocation, setServiceLocation] = useState(null);
  const handleChange = location => {
    if (location.selectedPlace) {
      setServiceLocation(location); //Sayo - set location to state instead of submitting
      //props.onSubmit({ location });
    }
  };

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const { rootClassName, className, intl, handleSubmit } = formRenderProps;
        const classes = classNames(rootClassName || css.root, className);

        // Allow form submit only when the place has changed
        //const preventFormSubmit = e => e.preventDefault();
        //const submitFormFn = isKeywordSearch ? handleSubmit : preventFormSubmit;
        const submitForm = values => {
          // Combine keyword and location into a single submission
          const { keywords } = values;
          props.onSubmit({ keywords, location: serviceLocation });
        };

        return (
          <Form className={classes} onSubmit={() => handleSubmit(submitForm)}>
            <div className={css.wrapper}>
              <div className={css.searchFieldsWrapper}>
                <KeywordSearchField intl={intl} />
                <LocationSearchField intl={intl} handleChange={handleChange} />
              </div>
              <button type="submit" className={css.searchSubmit}>
                <div className={css.searchInputIcon}>
                  <IconSearchDesktop />
                </div>
              </button>
            </div>
          </Form>
        );
      }}
    />
  );
};

SearchFormComponent.defaultProps = { rootClassName: null, className: null };

SearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SearchForm = injectIntl(SearchFormComponent);

export default SearchForm;
